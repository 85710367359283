<template>
  <div class="container">
    <div class="container">
      <b-card>
        <div slot="header">
          <strong>{{ $t('uploadSiteMap') }}</strong>
        </div>
        <b-row>
          <b-col sm="12">
            <b-form-file
              v-model="file"
              :placeholder="$t('chooseSiteMapFile')"
              accept=".xml"
            />
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col sm="12" md="6" lg="2">
            <b-button
              type="submit"
              block
              variant="primary"
              :disabled="!file"
              @click.prevent="submit"
            >{{ $t('save') }}</b-button>
          </b-col>
        </b-row>
      </b-card>

    </div>
  </div>
</template>

<script>

  import {APIService} from "../../services/api";

  export default {
    name: 'SiteMap',
    data: () => {
      return {
        file: null,
      }
    },
    methods: {
      async submit(){
        let formData = new FormData();
        formData.append('file', this.file);
        try {
          await APIService.post('seo', formData);
        } catch(e) {
          console.error(e);
        }
      },
    }
  }
</script>
